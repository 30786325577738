import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from '../content/Hero';
import Meta from '../../shared/Meta';
import CostEstimator from '../content/CostEstimator';
import Triple from '../content/Triple';
import VendorsToChooseFrom from '../VendorsToChooseFrom';
import CtaImage from '../content/CtaImage';
import LogoList from '../content/LogoList';
import AnyEvent from '../content/AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class BurgerCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Burger Food ${cityConfig.vehicle_type} Catering - Weddings, Parties & Events - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Fans of all ages love a good burger and fry! And these ${cityConfig.city_name} burger food ${vehicleType}s are a great way to feed guests at your next event. Compare quotes from top food ${vehicleType}s with our free catering request form below.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Burger Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/BURGERS-header.jpeg">
          <h1>Burger Food {cityConfig.vehicle_type} Catering</h1>
          <p>Fans of all ages love a good burger and fry! And these {cityConfig.city_name} burger food {vehicleType}s are a great way to feed guests at your next event. Compare quotes from top food {vehicleType}s with our free catering request form below.</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="NewSection">
          <h3>How it works</h3>
          <div className={tripleStyles.Triple_wrapper}>
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering1.png"
              title="1. Answer a few questions"
              text={`Answer a few questions about your upcoming event and we'll pair you with the best matches, scouring our list of over 500 of ${cityConfig.city_name}'s best food ${vehicleType}s.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering2.png"
              title="2. Compare quotes"
              text={`We’ll send you pricing quotes from available food ${vehicleType}s. Message them directly to find out more about their food or drink, setup, and the final details of your event.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering3.png"
              title={`3. Book your ${vehicleType}`}
              text={`Select your favorite food ${vehicleType} and book directly with the vendor. There's no added commission of services fees, so you'll always know you're getting the best deal.`}
            />
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Burger1.jpeg" imgClass="img-burger1"/>
              <div className={styles.Cta_copy}>
                <h4>Sweet dreams are made of cheese.</h4>
                <p>
                  Not your basic cheeseburger! These {cityConfig.city_name} food {vehicleType}s have taken their own unique approach to the delicious burger, topping our site as the most popular cuisine choice for events of all sizes! Bacon jam topped to three cheese-stuffed, these burger food {vehicleType}s are guaranteed to keep your guests happy. From {cityConfig.city_name}'s famous <Link to="/food-trucks/skillet-street-food">Skillet</Link> food {vehicleType}, known for slinging their famous burgers and poutine out of a vintage airstream, to gastropub-influenced <Link to="/food-trucks/bread-and-circuses">Bread &amp; Circus</Link> food {vehicleType}, who have customers travel from all over Washington to try their award-winning Circus burger, made with white American cheese, ground beef, crisp bacon, and so much more. These {vehicleType}s will keep you on your toes!
                </p>
                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Burger2.jpeg" imgClass="img-burger2"/>
              <div className={styles.Cta_copy}>
                <h4>You're the only bun for me.</h4>
                <p>
                  Burgers aren’t just for meat-eaters, vegetarians should check out <Link to="/food-trucks/the-peoples-burger">The Peoples Burger</Link> food {vehicleType}, whose seared portobello burger with aged cheddar, arugula, tomato, onion &amp; {vehicleType} sauce has people coming back for seconds! Or show your Seahawks pride with <Link to="/food-trucks/stacks-burgers">Stacks Burgers</Link> food {vehicleType} and their '12' burger with 1/2 lb beef, American &amp; pepperjack cheese, lettuce, pickles, avocado, grilled onions, bacon, house made stack sauce! Looking for late-night munchies? <Link to="/food-trucks/off-the-rez">Off The Rez</Link> food {vehicleType} takes burgers to a whole new level by sandwiching their beef patty with two handmade frybreads instead of a traditional burger bun, finished with crispy bacon, American cheese, lettuce, pickled onions and cumin crema.
                </p>
                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom options={{ food_categories: 'hamburgers' }} />

        <CostEstimator title="Burger Catering Cost Estimator"/>

        <section className="NewSection">
          <LogoList title="Some of the companies we serve" />
        </section>

        <AnyEvent/>

        <section className="NewSection">
          <div className={styles.BookingCta}>
            <h3>Think of us for your next event</h3>
            <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
            <a
              className="Button"
              href="/catering/register"
            >
              Food {cityConfig.vehicle_type} Catering
            </a>
          </div>
        </section>
      </div>
    );
  }
}

BurgerCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BurgerCatering);
